









import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ButtonBase
  },
})

export default class ImageReport extends Vue {
  private id = this.$route.params.id
  private note_report_comment = ''
  private image = ''

  public async mounted() {
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken.get(`/v3/user_note_reports/${this.id}`).then((res: any) => {
      this.image = res.data.note_report_canvas
      this.note_report_comment = res.data.note_report_comment ? res.data.note_report_comment : ''
    })
    Vue.prototype.$loading.complete()
  }
  
  private async backReport() {
    this.$router.push({
      path: `/student/v3/report`,
    })
  }
}
